import Aos from "aos";
import axios from "axios";
import Svg from "../component/Svg";
import { Link } from "react-router-dom";
import Carousel from "../component/Slider";
import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Container, Grid, Button } from "@mui/material";
import sliderImg3 from "../assets/images/carousel5.webp";
import sliderImg4 from "../assets/images/carousel4.webp";
import productTextImg from "../assets/images/productTextImg.webp";

export default function NewProduct() {
	const [title] = useState("Product - The BroadCast Store");
	const [description] = useState(
		"Broadcast Store is a professional audiovisual equipment company providing services like consignment, system integration, and media migration etc.",
	);

	const imgItem = [
		{ id: 3, src: sliderImg3 },
		{ id: 4, src: sliderImg4 },
	];

	const [brands, setBrands] = useState([]);
	const [categories, setCategories] = useState([]);
	const [allProducts, setAllProducts] = useState([]);
	const [filteredProducts, setFilteredProducts] = useState([]);
	const [selectedBrand, setSelectedBrand] = useState("all");
	const [selectedCategory, setSelectedCategory] = useState("all");
	const [selectedResolution, setSelectedResolution] = useState("all");
	const [loading, setLoading] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);
	const [showResolutionFilter, setShowResolutionFilter] = useState(false);
	const itemsPerPage = 8;

	useEffect(() => {
		document.title = title;
		document
			.getElementById("meta-description")
			.setAttribute("content", description);
		Aos.init({ duration: 2000 });
		window.scrollTo(0, 0);
	}, [title, description]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const [productsRes, brandsRes, categoriesRes] = await Promise.all([
					axios.get("https://thebroadcaststore.co/admins/api/all-products"),
					axios.get("https://thebroadcaststore.co/admins/api/all-brands"),
					axios.get("https://thebroadcaststore.co/admins/api/all-category"),
				]);

				const filteredProducts = productsRes.data.filter(
					(p) => p.new_product === 1,
				);
				const filteredCategories = categoriesRes.data.filter((c) =>
					c.title.toLowerCase().includes("new"),
				);

				setAllProducts(filteredProducts);
				setFilteredProducts(filteredProducts);
				setBrands(brandsRes.data);
				setCategories(filteredCategories);
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		let filtered = allProducts;

		if (selectedBrand !== "all") {
			filtered = filtered.filter((p) => p.brand_id === selectedBrand);
		}

		if (selectedCategory !== "all") {
			filtered = filtered.filter((p) => p.category_id === selectedCategory);
		}

		if (selectedResolution !== "all") {
			filtered = filtered.filter((p) =>
				p.resolution?.toLowerCase().includes(selectedResolution),
			);
		}

		setFilteredProducts(filtered);
		setShowResolutionFilter(filtered.some((p) => p.resolution !== null));
	}, [selectedBrand, selectedCategory, selectedResolution, allProducts]);

	const indexOfLastProduct = currentPage * itemsPerPage;
	const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
	const currentProducts = filteredProducts.slice(
		indexOfFirstProduct,
		indexOfLastProduct,
	);
	const pageNumbers = Array.from(
		{ length: Math.ceil(filteredProducts.length / itemsPerPage) },
		(_, i) => i + 1,
	);

	const handleCategoryChange = (categoryId) => {
		setSelectedCategory(categoryId.toString());
		setCurrentPage(1);
	};

	const resetFilters = () => {
		setSelectedBrand("all");
		setSelectedCategory("all");
		setSelectedResolution("all");
		setCurrentPage(1);
	};

	const handleNextClick = () => {
		if (currentPage < pageNumbers.length) {
			setCurrentPage(currentPage + 1);
		}
	};

	return (
		<>
			<div className="carousel-container1">
				<Carousel
					slides={imgItem}
					autoSlide={true}
					autoSlideInterval={5000}
				/>
				<div className="contact-bannerText product-slider-text">
					<img
						src={productTextImg}
						alt="product-bannerText-Img"
						className="product-bannerText-Img"
					/>
					<h1
						className="contact-heading aos-init aos-animate product-slider-heading"
						data-aos="fade-up">
						New Products
					</h1>
				</div>
			</div>

			{loading ? (
				<div className="loading-container">Loading...</div>
			) : (
				<Grid
					className="ft-products brd-line"
					id="all-pro">
					<Container>
						<div className="ft-pro-head">
							<Row className="products-heading">
								<Col>
									<span className="webkit-text products-bgtext">PRODUCTS</span>
									<div data-aos="fade-up">
										<h2 className="heading">Featured Products</h2>
									</div>
								</Col>
							</Row>
						</div>

						<div className="filter-product">
							<div className="filter-sidebar">
								<div className="filter-sidebar2">
									<h3 className="filter-sidebar2-title">Categories</h3>
									<div className="check-box scrollable-list">
										<div className="brands-check-box">
											<input
												type="radio"
												id="all_categories"
												name="category"
												checked={selectedCategory === "all"}
												onChange={() => setSelectedCategory("all")}
											/>
											<label
												htmlFor="all_categories"
												className="check-box-para">
												All Categories
											</label>
										</div>
										{categories.map((category) => (
											<div
												className="brands-check-box"
												key={category.id}>
												<input
													type="radio"
													id={`category_${category.id}`}
													name="category"
													checked={selectedCategory === category.id.toString()}
													onChange={() => handleCategoryChange(category.id)}
												/>
												<label
													htmlFor={`category_${category.id}`}
													className="check-box-para">
													{category.title.slice(3)}
												</label>
											</div>
										))}
									</div>
								</div>

								<div className="filter-sidebar2">
									<h3 className="filter-sidebar2-title">Brands</h3>
									<div className="check-box scrollable-list">
										<div className="brands-check-box">
											<input
												type="radio"
												id="all_brands"
												name="brand"
												checked={selectedBrand === "all"}
												onChange={() => setSelectedBrand("all")}
											/>
											<label
												htmlFor="all_brands"
												className="check-box-para">
												All Brands
											</label>
										</div>
										{brands.map((brand) => (
											<div
												className="brands-check-box"
												key={brand.id}>
												<input
													type="radio"
													id={`brand_${brand.id}`}
													name="brand"
													checked={selectedBrand === brand.id}
													onChange={() => setSelectedBrand(brand.id)}
												/>
												<label
													htmlFor={`brand_${brand.id}`}
													className="check-box-para">
													{brand.title}
												</label>
											</div>
										))}
									</div>
								</div>

								{showResolutionFilter && (
									<div className="filter-sidebar2">
										<h3 className="filter-sidebar2-title">Resolution</h3>
										<div className="check-box">
											<div className="brands-check-box">
												<input
													type="radio"
													id="all_resolution"
													name="resolution"
													checked={selectedResolution === "all"}
													onChange={() => setSelectedResolution("all")}
												/>
												<label
													htmlFor="all_resolution"
													className="check-box-para">
													All Resolutions
												</label>
											</div>
											{["8k", "6k", "4k", "1080"].map((res) => (
												<div
													className="brands-check-box"
													key={res}>
													<input
														type="radio"
														id={`resolution_${res}`}
														name="resolution"
														checked={selectedResolution === res}
														onChange={() => setSelectedResolution(res)}
													/>
													<label
														htmlFor={`resolution_${res}`}
														className="check-box-para">
														{res.toUpperCase()}
													</label>
												</div>
											))}
										</div>
									</div>
								)}
							</div>

							<div className="product-page">
								<div className="products-container">
									{currentProducts.length > 0 ? (
										currentProducts.map((product) => (
											<div
												className="ftrd-slide"
												key={product.id}>
												<div className="ftrd-pro">
													<div className="product">
														<h4 className="title">{product.title}</h4>
														<div className="pro-image">
															<img
																src={`https://thebroadcaststore.co/admins/public/${product.img_path}`}
																alt={product.title}
															/>
														</div>
														<p className="short-des">{product.short_desc}</p>
														<div className="price-btn">
															<p className="price">Call for Price</p>
															<Button className="lrn-more cart">
																<Link to={`/product-detail/${product.slug}`}>
																	Learn More
																</Link>
															</Button>
														</div>
													</div>
												</div>
											</div>
										))
									) : (
										<div className="reset-btn-container">
											<h3 className="reset-title">No products found!</h3>
											<div className="reset-filters">
												<Button
													variant="contained"
													className="reset-button"
													onClick={resetFilters}>
													Reset Filters
												</Button>
											</div>
										</div>
									)}
								</div>
								<Svg />
								{pageNumbers.length > 1 && (
									<div className="pagination">
										{pageNumbers.slice(0, 6).map((number) => (
											<button
												key={number}
												onClick={() => setCurrentPage(number)}
												className={number === currentPage ? "active" : ""}>
												{number}
											</button>
										))}
										{pageNumbers.length > 6 &&
											currentPage < pageNumbers.length && (
												<button
													key="next"
													onClick={handleNextClick}
													className={currentPage === 7 ? "active" : ""}>
													Next
												</button>
											)}
									</div>
								)}
							</div>
						</div>
					</Container>
				</Grid>
			)}
		</>
	);
}
